import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import React from 'react';


const COMPANY_TYPE_DELIVERY = "DELIVERY"
const COMPANY_TYPE_SERVICE = "SERVICE"


export default class App extends React.Component {

    lang_da = {
        shorthand : "da",
        we_are_having_trouble_contacting_the_server : "Vi har problemer med at kontakte serveren",
        fetching_the_data : "Henter data",
        we_have_no_information_about_this_delivery : "Vi har ingen informationer om denne levering",
        service_was_done_by : "Servicen blev udført kl",
        drop_was_done_by : "Droppet blev udført kl",
        service_was_failed_by : "Servicen blev registreret fejlet kl",
        drop_was_failed_by : "Droppet blev registreret fejlet kl",
        documentation : "Dokumentation",
        document : "Dokument",
        image : "billede",
        video : "video",
        signature : "underskrift",
        unknown_document_type : "ukendt dokumenttype",
        diroto_description : "Diroto ruteoptimering, ruteplanlægning og flådestyring",
        contact : "Kontakt",
        customer_service : "kundeservice",
    }

    lang_sv = {
        shorthand : "sv",
        we_are_having_trouble_contacting_the_server : "Vi har problem med att kontakta servern",
        fetching_the_data : "Hämtar data",
        we_have_no_information_about_this_delivery : "Vi har ingen information om den här leveransen",
        service_was_done_by : "Service utfördes kl",
        drop_was_done_by : "Leveransen skedde kl",
        service_was_failed_by : "Servicen registrerades som misslyckad kl",
        drop_was_failed_by : "Leveransen registrerades som misslyckad kl",
        documentation : "Dokumentation",
        document : "Dokument",
        image : "bild",
        video : "video",
        signature : "signatur",
        unknown_document_type : "okänd dokumenttyp",
        diroto_description : "Diroto ruttoptimering, ruttplanering och flotthantering",
        contact : "Kontakta",
        customer_service : "kundtjänst",
    }

    constructor(props) {
        super(props);
        this.state = {
           last_response: null,
           server_error: false,
           lang: window.navigator.language.toLowerCase().startsWith("sv") ? this.lang_sv : this.lang_da,
        }
        this.get_callable_contact_number = this.get_callable_contact_number.bind(this);
        this.get_status_text_line = this.get_status_text_line.bind(this);
        this.format_timestamp = this.format_timestamp.bind(this);
        this.zero_pad_string = this.zero_pad_string.bind(this);
        this.file_attachment = this.file_attachment.bind(this);
        this.get_attachments = this.get_attachments.bind(this);
        this.select_language = this.select_language.bind(this);
    }

    componentDidMount() {
        const url_parts = window.location.href.split("/");
        let get_variables = url_parts[url_parts.length - 1];
        get_variables = "tracking_identifier=" + get_variables;

        const update_status = () => {
            fetch(process.env.REACT_APP_CLOUD_FUNCTION + "?" + get_variables)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        last_response: result,
                        server_error: false,
                    });
                },
                (error) => {
                    this.setState({
                      server_error: true,
                    });
                },
            );
        };
        setInterval(update_status, 1000 * 60 * 2);
        update_status();
    }

    select_language(l) {
        if (l === "sv") {
            this.setState({
                lang : this.lang_sv,
            });
        } else {
            this.setState({
                lang : this.lang_da,
            });
        }
    }

    zero_pad_string(s, p) {
        return s.toString().padStart(p, '0');
    }

    format_timestamp(timestamp1) {
        const current_datetime = new Date();
        const datetime1 = new Date(timestamp1 * 1000);

        const time_string_1 = this.zero_pad_string(datetime1.getHours(), 2) + ":" + this.zero_pad_string(datetime1.getMinutes(), 2);
        const date_string_1 = this.zero_pad_string(datetime1.getDate(), 2) + "/" + this.zero_pad_string(datetime1.getMonth()+1, 2) + "/" + datetime1.getFullYear();

        function is_same_date(datetime1, datetime2) {
            return datetime1.getMonth() === datetime2.getMonth() && datetime1.getDate() === datetime2.getDate() && datetime1.getFullYear() === datetime2.getFullYear();
        }

        function is_today(datetime) {
            return is_same_date(datetime, current_datetime);
        }

        if (is_today(datetime1)) {
            return time_string_1;
        } else {
            return time_string_1 + " " + date_string_1;
        }
    }

    get_callable_contact_number() {
        if (this.state.last_response.account.contact_number) {
            return ("tel:" + this.state.last_response.account.contact_number);
        } else {
            return "";
        }
    }

    get_status_text_line() {
        const delivery_time_stamp = this.state.last_response?.drop?.timestamp;
        let delivery_time_string = "";
        if (delivery_time_stamp) {
            delivery_time_string = this.format_timestamp(this.state.last_response.drop.timestamp);
        }

        if (this.state.server_error) {
            return this.state.lang.we_are_having_trouble_contacting_the_server;
        } else if (!this.state.last_response) {
            return this.state.lang.fetching_the_data;
        } else if (!(this.state.last_response?.success)) {
                return this.state.lang.we_have_no_information_about_this_delivery;
        } else if (this.state.last_response?.drop?.status == "OK"){
            switch (this.state.last_response.account.company_type) {
                case COMPANY_TYPE_SERVICE:
                    return this.state.lang.service_was_done_by + " " + delivery_time_string;
                default:
                    return this.state.lang.drop_was_done_by + " " + delivery_time_string;
            }
        } else {
            switch (this.state.last_response.account.company_type) {
                case COMPANY_TYPE_SERVICE:
                    return this.state.lang.service_was_failed_by + " " + delivery_time_string;
                default: 
                    return this.state.lang.drop_was_failed_by + " " +  delivery_time_string;
            }
        }
    }

    get_attachments() {
        if (this.state.server_error || !this.state.last_response || !(this.state.last_response.files?.length > 0)) {
            return null;
        }

        return (
            <>
                <h5>{this.state.lang.documentation}</h5>
                {this.state.last_response?.files?.map(this.file_attachment)}
            </>
        );
    }

    file_attachment(file, index) {
        let get_media = (file) => {
            switch (file.type) {
                case "IM": 
                    return (
                        <img src={file.url} alt="Document" className="delivery-file image"></img>
                    );
                case "SI": 
                    return (
                        <img src={file.url}  alt="Signature" className="delivery-file signature"></img>
                    );
                case "VO":
                    return (
                        <video src={file.url}  alt="Document" controls className="delivery-file image"></video>
                    );
                default:
                    return null;
            }
        }

        let get_label = (typ) => {
            switch (typ) {
                case "IM":
                    return this.state.lang.image
                case "VO":
                    return this.state.lang.video
                case "SI":
                    return this.state.lang.signature
                default: 
                    return this.state.lang.unknown_document_type
            }
        };

        return (
           <div key={file.url} className='text-center mb-4 '>
               <span className={'delivery-file-wrapper d-inline-block ' + file.type}>
                {get_media(file)}
               </span>
               <p className='text-muted'>{this.state.lang.document} {index + 1} ({get_label(file.type)}) <a href={file.url} target='_blank' className=''><i className='fas fa-download'></i></a></p>
           </div> 
        );
    }

    render() {
        return (
          <div id="wrapper" className="d-flex flex-column vh-100">
              <nav className="navbar navbar-dark flex-0 text-center">
                <img className="navbar-brand navbar-logo m-auto" src="diroto-white.svg" alt='Diroto logo'/>
              </nav>
                <div className="container mt-4">
                   <div className="row justify-content-md-center">
                     <div className="col col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                        <div className="card capture-pointer-events shadow">
                          <div className="card-body">
                            <div className="float-right-top">
                              <img className={"flag " + (this.state.lang.shorthand === "sv" ? "flag-selected" : "flag-not-selected")} src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/0.8.2/flags/4x3/se.svg" alt="Svenska" width="24" height="19" onClick={(e) => this.select_language('sv')}></img>
                              <img className={"flag " + (this.state.lang.shorthand === "da" ? "flag-selected" : "flag-not-selected")} src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/0.8.2/flags/4x3/dk.svg" alt="Dansk" width="24" height="19" onClick={(e) => this.select_language('da')}></img>
                            </div>
                            <h4 className="card-title">{this.get_status_text_line()}</h4>
                            <h5 className="card-subtitle mb-2 text-muted">{this.state.last_response?.account ? <span>{this.state.last_response.account.name}<br/></span> : null}</h5>
                            {this.get_attachments()}
                            {this.state.last_response?.account ? <div><a href={this.get_callable_contact_number()} className="btn btn-primary text-nowrap btn-block contact-button" role="button"><i className="fas fa-phone-alt"></i>&nbsp;{this.state.lang.contact}&nbsp;{this.state.lang.customer_service} <small>{this.state.last_response.account.contact_number}</small></a></div> : null }
                          </div>
                        </div>
                     </div>
                  </div>
              </div>
              <div className="flex-grow-1 flex-shrink-1 h-100 position-relative">
              </div>
              <footer className="flex-0 text-center p-2 text-white">
                Copyright &copy;2022 All rights reserved | {this.state.lang.diroto_description}
              </footer>
           </div>
        );
    }
}
